"use strict";

$(function () {
  if ($(".form.registration").length > 0) {
    var $body = $("body");
    var $stepHeader = $("header .new-design");
    var $animContainer = $(".animation-container");

    formReset();
    setupBackLinkAction();

    $.each($(".form.registration form input[required], .form.registration form select[required] "), function () {
      if ($(this).val() != "") {
        $(this).addClass("valid");
      }
    });
    $("#registrationStep1").validate({
      onfocusout: function onfocusout(element) {
        this.element(element);
        if ($(element).attr('id') != 'email') {
          setupAccentWhenValidForm("registrationStep1", 5);
        }
      },
      ignore: ".ignore, :hidden",
      rules: {
        email: {
          isValidEmail: true,
          remote: {
            url: "/api/EmailValidation/EmailValidationRules",
            type: "GET",
            data: {
              email: function email() {
                return $("#email").val();
              },
              validateUsername: true
            },
            dataFilter: function dataFilter(response) {
              var data = JSON.parse(response);
              var emailInput = $("#email");
              var emailValue = emailInput.val();

              if (data.isValid) {
                return true;
              }

              if (data.errorType === "EmailDomainError") {
                return JSON.stringify(emailInput.data("msg-email"));
              } else if (data.errorType === "EmailReservedDomainError") {
                return JSON.stringify(emailInput.data("msg-hasreserveddomain"));
              } else if (data.errorType === "EmailAlreadyRegistered") {
                var registeredUserError = emailInput.data("emailinuse").replace("{0}", emailValue);
                return JSON.stringify(registeredUserError);
              }
            }
          }
        },
        password: {
          strongPassword: true
        },
        confirmPassword: {
          equalTo: "#password"
        }
      },
      errorElement: "div",
      errorPlacement: function errorPlacement(error, element) {
        setupErrorPlacementValidation(error, element);
        setupAccentWhenValidForm("registrationStep1", 5);
      },
      submitHandler: function submitHandler(form, event) {
        //always if form is valid
        event.preventDefault();
        showSecondForm();
      },
      success: function success(label, element) {
        // Remove the error message if the field is now valid
        $(label).remove();

        // Optionally add valid class
        $(element).addClass("valid");
        if ($(element).attr('id') === 'email') {
          setupAccentWhenValidForm("registrationStep1", 5);
        }
      }

    });

    // Custom validator for date range
    $.validator.addMethod("dateRange", function (value, element) {
      if (!value) return false; // Ensure value is entered

      var inputYear = parseInt(value, 10);
      var currentYear = new Date().getFullYear();
      var minYear = currentYear - 95;
      var maxYear = currentYear - 16;

      return inputYear >= minYear && inputYear <= maxYear;
    }, function (params, element) {
      var currentYear = new Date().getFullYear();
      var minYear = currentYear - 95;
      var maxYear = currentYear - 16;

      // Get the message from data-msg-range and replace tokens
      var messageTemplate = $(element).data("msg-range") || "Please enter a value between {minYear} and {maxYear}.";
      var newDataMsg = messageTemplate.replace("{minYear}", minYear).replace("{maxYear}", maxYear);
      $(element).removeData("msg-range").data("msg-range", newDataMsg);
      $(element).attr("data-msg-range", newDataMsg);

      return $(element).data("msg-range");
    });

    // Dynamically apply the validation class
    $.validator.addClassRules({
      yearFieldValidate: {
        dateRange: true
      }
    });

    // Add the validation class to the input
    $("#year").addClass("yearFieldValidate");

    $("#registrationStep2").validate({
      onfocusout: function onfocusout(element) {
        this.element(element);
        setupAccentWhenValidForm("registrationStep2", 7);
      },
      errorElement: "div",
      errorPlacement: function errorPlacement(error, element) {
        setupErrorPlacementValidation(error, element);
      },
      rules: {
        birthDate: {
          required: function required() {
            var month = $("#month").val().trim();
            var day = $("#day").val().trim();
            var year = $("#year").val().trim();
            return month === "" || day === "" || year === "";
          }
        },
        year: {
          dateRange: true // Ensure the custom rule is applied
        }
      },
      messages: {
        birthDate: function birthDate() {
          return $("#year").data("msg-required") || "Please enter your complete date of birth.";
        },
        year: function year() {
          var currentYear = new Date().getFullYear();
          var minYear = currentYear - 95;
          var maxYear = currentYear - 16;
          return "Your age must be between 16 and 95 years. Please enter 1 a value between " + minYear + " and " + maxYear + ".";
        }
      },
      groups: {
        birthDate: "month day year" // 🔹 Agrupa los tres campos en un solo error
      },
      submitHandler: function submitHandler(form, event) {
        event.preventDefault();
        var $forms = $("form");
        var optIn = true;
        var data = $("#registrationStep1").serialize() + "&" + $("#registrationStep2").serialize() + "&optIn=" + optIn;
        var url = $("#registrationStep2").attr("action");

        $.ajax({
          type: "POST",
          url: url,
          data: data,
          beforeSend: function beforeSend() {
            $(form).find(".fa-spinner").show();
          },
          complete: function complete() {
            $(form).find(".fa-spinner").hide();
          },
          success: function success(response, textStatus, xhr) {
            var model = response.Data.Model;
            var errors = response.Data.Errors;
            var statusCode = xhr.status;

            if (model.GtmAction) {
              executeGtm(model.GtmAction);
            }

            if (statusCode == 200 && response.Success && errors.IsProcessedSucessfully) {
              setRegistrationModalEvents(model.RequestedPage, model.IsCurrentMember);
            } else {
              if (statusCode == 200 && !response.Success) {
                if (model.UrlRedirection) {
                  window.location.href = model.UrlRedirection;
                }
                if (errors.HasGeneralError) {
                  $(".error-bar").addClass("show");
                }
                var currentStep = 2,
                    stepNumber = 2;
                var $serverErrors = $forms.find(".floating-error-messages .server-error");
                $serverErrors.remove();

                // 🔹 NUEVA LÓGICA: Concatenar errores en lugar de reemplazarlos
                var groupedErrors = {};

                errors.ErrorList.forEach(function (error) {
                  var field = error.FieldName;
                  var fieldGroup = $("div[data-group='" + field + "'] .floating-error-messages");

                  if (!groupedErrors[field]) {
                    groupedErrors[field] = [];
                  }

                  error.ErrorType.forEach(function (errorType) {
                    var errorMessage = "";
                    switch (errorType) {
                      case "InvalidCharacters":
                        errorMessage = $("#" + field).data("msg-alphanumber");
                        break;
                      case "Length":
                        errorMessage = $("#" + field).data("msg-maxlength");
                        break;
                      case "MinLength":
                        errorMessage = $("#" + field).data("msg-minlength");
                        break;
                      case "EmailFormat":
                        errorMessage = $("#" + field).data("msg-email");
                        break;
                      case "Warning":
                        $("div[data-group='" + field + "'] .floating-messages").find(".warning").show();
                        break;
                      case "EmailInUse":
                        errorMessage = $("#" + field).data("emailinuse").replace("{0}", model.Email);
                        break;
                      case "PasswordRequirement":
                        errorMessage = $("#password").data("msg-strong-password");
                        break;
                      case "PasswordNotEqual":
                        errorMessage = $("#" + field).data("msg-equalto");
                        break;
                      case "InvalidDate":
                        errorMessage = $("#year").data("msg-isvaliddate");
                        break;
                      case "AgeRequirement":
                        errorMessage = $("#year").data("msg-range");
                        break;
                      default:
                        if (field === "birthDate") {
                          field = "year";
                        }
                        errorMessage = $("#" + field).data("msg-required");
                    }

                    if (errorMessage && !groupedErrors[field].includes(errorMessage)) {
                      groupedErrors[field].push(errorMessage);
                    }
                  });

                  stepNumber = $("div[data-group='" + field + "']").parents(".form-step").data("step");
                });

                // 🔹 Mostrar errores concatenados
                Object.keys(groupedErrors).forEach(function (field) {
                  var fieldGroup = $("div[data-group='" + field + "'] .floating-error-messages");
                  var combinedErrorMessage = "<div class='server-error'>" + groupedErrors[field].join("<br>") + "</div>";
                  fieldGroup.append($(combinedErrorMessage));
                });

                if (currentStep !== stepNumber) {
                  formReset();
                }
              }
            }
          },
          error: function error(response) {
            window.location.href = "/";
          }
        });
      },
      invalidHandler: function invalidHandler(event, validator) {
        event.preventDefault();
        dataLayerPush({
          event: "registration",
          registrationAction: "submit",
          registrationResult: "failed"
        }, $(this));
      }
    });
  }

  /**
   * Resets the form back to the default state.
   */
  function formReset() {
    $(".js-form-step").removeClass("left leaving");
    $(".js-form-step").not('.js-form-step[data-step="1"]').addClass("hidden-step waiting");
    $('.js-form-step[data-step="1"]').removeClass("hidden-step");

    $animContainer.css({
      paddingBottom: $('.js-form-step[data-step="1"]').height() + 105
    });

    // Ensure top of form is in view
    $("html, body").animate({
      scrollTop: $stepHeader.offset().top
    });
    return false;
  }

  /**
   * Set Interaction/hide and show to go between form steps
   */
  function showSecondForm() {
    var $currentForm = $('.js-form-step[data-step="1"]');
    var $nextForm = $('.js-form-step[data-step="2"]');

    $body.addClass("freeze");

    // Ensure top of form is in view
    $("html, body").animate({
      scrollTop: $stepHeader.offset().top
    });

    // Hide current form fields
    $currentForm.addClass("leaving");
    setTimeout(function () {
      $currentForm.addClass("hidden-step");
    }, 500);

    // Animate container to height of form
    $animContainer.css({
      paddingBottom: $nextForm.height() + 105
    });

    // Show next form fields
    $nextForm.removeClass("hidden-step").addClass("coming").one("webkitTransitionEnd transitionend", function () {
      $nextForm.removeClass("coming waiting");
    });

    $body.removeClass("freeze");
    return false;
  }

  /**
   * Set action for back link
   */
  function setupBackLinkAction() {
    $(".back-link").on("click", function (e) {
      e.preventDefault();
      formReset();
    });
  }

  /**
   * Set registration modal events after registration successfull
   */
  function setRegistrationModalEvents(requestedPage, currentMember) {
    $("#btnModalRegistration").on("click", function (e) {
      e.preventDefault();
      window.location.href = requestedPage;
    });

    $("#registrationModal").on("hidden.bs.modal", function (e) {
      window.location.href = requestedPage;
    });

    if (currentMember) {
      $("#message-current-member").removeClass("hide");
    } else {
      $("#message-normal").removeClass("hide");
    }

    $("#registrationModal").modal("show");
  }

  /**
   * Execute GTM string function coming from BE
   */
  function executeGtm(functionText) {
    return Function('"use strict";' + functionText + "")();
  }

  if (!$("form#login").length > 0) {
    $("#password").on("focus", function () {
      $(".checklist li").addClass("list-item");
      passwordValidation("password");
    });
  }
  function passwordValidation(passwordId) {
    var passwordInp = $("#" + passwordId);

    var validationRegex = [{ regex: /.{8,}/ }, // min 8 letters,
    { regex: /[a-zA-Z]/ }, // letters from a - z
    { regex: /[0-9]/ }, // numbers from 0 - 9
    { regex: /[^A-Za-z0-9]/ }];

    passwordInp.on("keyup", function () {
      var passwordChecklist = document.querySelectorAll(".list-item");
      validationRegex.forEach(function (item, i) {
        var trimVal = passwordInp.val();
        passwordInp.val(trimVal);
        var isValid = item.regex.test(trimVal);
        if (passwordChecklist.length > 0) {
          if (isValid) {
            if (i != 3) passwordChecklist[i].classList.add("checked");else passwordChecklist[i].classList.remove("checked");
          } else {
            if (i != 3) {
              passwordChecklist[i].classList.remove("checked");
            } else {
              passwordChecklist[i].classList.add("checked");
            }
          }
        }
        if ($("#password").val() == "") {
          $(".checklist li").removeClass("checked");
        }
      });
    });
  }
});